/* body {
    font-family: "system-ui", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  .system-ui {
    font-family: "system-ui", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  } */
  * {
    font-family:   'nunito','Segoe UI',sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-optical-sizing: auto;
     /* Slight letter spacing adjustment */
    text-rendering: optimizeLegibility;  /* Better text rendering */
  }
  
  /* Optional: Different text styles for different heading levels */
 
  
  /* Optional: Paragraph text style */
  
  
  /* Optional: Link text style */
 
  /* .nunito{
    font-family: 'system-ui', sans-serif;
  }
  .{
    font-family: "system-ui", sans-serif;
  } */

  
  .circular-image {
    /* Use 50% to make the image circular */
    border-radius: 50%;
    
    /* Set width and height to the same value to maintain aspect ratio */
    width: 120px;
    height: 120px;
  
    /* Optional: to handle overflow if the image is not perfectly square */
    overflow: hidden;
   
    /* Optional: add a border if desired */
   
    
    /* To ensure the image scales nicely */
    object-fit: cover;
  }


  /*----------------SCROLL BAR-------------------*/

/* for default mode */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #cccccc; */
  background: #a6a6a6;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ababab;
}


pre {
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  word-break: break-word;
  color: #242424;
  overflow: auto;
}

.ql-editor {
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  word-break: break-word;
  color: #242424;
  overflow: auto;
}

.generalText{
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  padding: 10px;
  margin: 10px 0;
  line-height: 1.5;
  word-break: break-word;
  color: #242424;
  overflow: auto;
}
.generalTextmini{
  white-space: pre-wrap;
  font-family: 'Courier New', Courier, monospace;
  font-size: 13px;
  padding: 10px;
  margin: 10px 0;
  line-height: 1.5;
  word-break: break-word;
  color: #242424;
  overflow: auto;
}

.ql-container.ql-snow{
  border: none;
}
.ql-container.ql-snow.ql-disabled{
  border: none;
}

.counterimg{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

/* Medium screens (md) */
@media screen and (max-width: 1024px) {
  .counterimg {
    left: 90vw;
  }
}

/* Small tablets and large phones (sm) */
@media screen and (max-width: 768px) {
  .counterimg {
    left: 79vw;
  }
}

/* Extra small devices (phones) */
@media screen and (max-width: 480px) {
  .counterimg {
    left: 76vw;
  }
}